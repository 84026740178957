import { ProductStatus } from '../../../enums'
import { DeductionData } from '../../../interfaces/Dashboard'
import { DeductionsData, AccumulateInfos } from '../interfaces'

const accumulateInfos = (APIData: Array<DeductionData>) => {
  const accumulate = {}

  APIData.forEach((data: DeductionData) => {
    let type = data.type!.toLowerCase()
    const key = isPending(data.status!) ? `${type}_pending` : type

    if (key in accumulate) {
      accumulate[key].push({
        amount: data.amount,
        status: data.status,
      })
    } else {
      accumulate[key] = [
        {
          amount: data.amount,
          status: data.status,
        },
      ]
    }
  })

  return accumulate
}

const sum = (a: number, b: number): number => a + b

const sumAmount = (arr: AccumulateInfos[]): number => {
  return arr.map(item => parseFloat(item.amount.toString())).reduce(sum, 0)
}

const sumAmountOnlyApproved = (arr: AccumulateInfos[]): number => {
  const result = arr.filter(({ status }) => status === ProductStatus.Approved)
  return sumAmount(result)
}

const isPending = (status: string) => {
  return (
    status === ProductStatus.Pending ||
    status === ProductStatus.PendingFormalization
  )
}

const createDataStructure = (APIData: Array<DeductionData>): DeductionsData => {
  const data = accumulateInfos(APIData)
  const result: any = {
    deductions: [],
    total: 0,
    isPending: false,
  }

  if (!APIData?.length) {
    return result
  }

  const accumulateArr = Object.entries<AccumulateInfos[]>(data)

  accumulateArr.forEach(([key, arr]) => {
    const amount = sumAmount(arr)
    result.deductions.push({
      productType: key.replace(/_pending/g, '').toUpperCase(),
      amount,
      quantity: arr.length,
      isPending: !!arr.find(item => isPending(item.status)),
    })

    result.total += sumAmountOnlyApproved(arr)
  })

  return result
}

export { createDataStructure }
