import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/react'

interface LineProps {
  marginTop: number
  marginBottom: number
  marginRight: number
  marginLeft: number
  orientation: 'vertical' | 'horizontal'
  color: string
}

export const LineContainer = styled.div<LineProps>(
  ({
    theme,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    orientation,
    color,
  }) => css`
    ${orientation === 'horizontal' &&
      css`
        border-top: 1px solid ${selectTheme(color)({ theme })};
        margin-top: ${marginTop}px;
        margin-bottom: ${marginBottom}px;
      `}

    ${orientation === 'vertical' &&
      css`
        border-left: 1px solid ${selectTheme(color)({ theme })};
        margin-left: ${marginLeft}px;
        margin-right: ${marginRight}px;
      `}
  `,
)
