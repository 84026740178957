import { envVars } from '../envVars'

class RequestManager {
  private static _url: string

  static set url(id: string) {
    const url =
      {
        DEXTER: envVars.API,
      }[id] || ''

    this._url = url
  }

  static get url(): string {
    return this._url
  }

  static set authToken(token: string) {
    sessionStorage.setItem('authToken', token)
  }

  static get authToken() {
    return sessionStorage.getItem('authToken') ?? ''
  }
}

export { RequestManager }
