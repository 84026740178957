import { selectTheme } from '@creditas-ui/react'
import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'

const DeductionTitle = styled(Typography)`
  margin-bottom: 12px;

  ::after {
    content: '';
    display: block;
    width: 32px;
    height: 4px;
    background-color: ${selectTheme('colors.primary.60')};
    margin-top: 8px;
    border-radius: 4px;
  }
`

export { DeductionTitle }
