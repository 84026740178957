export type ValidationRule = [Function, string]

export function handleComponentId(
  dataRequest: Object,
  arr: Array<ValidationRule>,
): string {
  try {
    const index = arr.findIndex((item: ValidationRule) => {
      const [condition] = item
      return condition(dataRequest)
    })

    const [, componentId] = arr[index]
    return componentId
  } catch (e) {
    console.error("Product isn't mapped")
    return ''
  }
}
