import React from 'react'
import { LineContainer } from './Line.style'

interface LineProps {
  marginTop?: number
  marginBottom?: number
  marginRight?: number
  marginLeft?: number
  orientation?: 'vertical' | 'horizontal'
  color?: string
}

export function Line({
  orientation = 'horizontal',
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  color = 'colors.neutral.20',
}: LineProps) {
  return (
    <LineContainer
      data-testid="line-component"
      orientation={orientation}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      color={color}
    />
  )
}
