import { formatCurrency, getMonthName } from '../../../helpers'
import { DeductionsData } from '../interfaces'

const formatDataStructure = ({
  total,
  deductions,
  referenceMonthName,
}: DeductionsData) => {
  return {
    referenceMonthName: getMonthName('pt-BR', referenceMonthName),
    total: formatCurrency('pt-BR', parseFloat(total)),
    deductions: deductions?.map(item => ({
      ...item,
      amount: formatCurrency('pt-BR', parseFloat(item.amount)),
    })),
  }
}

export { formatDataStructure }
