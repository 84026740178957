import { selectTheme } from '@creditas-ui/react'
import { styled } from '@creditas-ui/system'
import tokens from '@creditas/tokens'

const Box = styled.div`
  width: 100%;

  .deductions-container {
    margin-top: 24px;
    border: 1px solid ${selectTheme('colors.neutral.20')};
    border-radius: 8px;
    padding: 24px 16px;

    @media (max-width: 545px) {
      margin-top: 8px;
    }

    .list-deductions {
      .item-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .box {
          font-size: 16px;
          font-weight: 300;

          &.isPending {
            svg {
              fill: currentColor;
            }
          }
        }
      }
    }

    .total {
      color: ${tokens.bColorneutral08};
      border-top: 1px solid ${tokens.bColorneutral03};
      align-items: center;
      padding-top: 24px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        color: ${tokens.bColorneutral07};
        font-size: 16px;
        font-weight: 600;

        @media (max-width: 545px) {
          width: 50%;
        }
      }

      strong {
        color: ${tokens.bColorneutral07};
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .list-deductions .box,
  .total .box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export { Box }
