export const envVars = {
  appName: 'loan-status',
  release: process.env.COMMIT_RELEASE,
  environment: process.env.name,
  API: process.env.API,
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
  showRestructuredInstallmentAlert:
    process.env.SHOW_RESTRUCTURED_INSTALLMENT_ALERT === 'true',
}
