import { RequestManager } from './'

class API {
  get(_endpoint): Promise<any> {
    return window
      .fetch(RequestManager.url + _endpoint, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: RequestManager.authToken,
        }),
      })
      .then(this.handleResponse)
      .catch(error => {
        throw new Error(error)
      })
  }

  post(_endpoint, _body): Promise<any> {
    return window
      .fetch(RequestManager.url + _endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: _body,
      })
      .then(this.handleResponse)
      .catch(error => {
        throw new Error(error)
      })
  }

  private handleResponse(_res) {
    return _res.ok ? _res.json() : Promise.reject(_res.statusText)
  }
}

export const Api = new API()
