import React from 'react'
import { Typography } from '@creditas-ui/react'
import { TextAndSubtextContainer } from './TextAndSubtext.style'

interface TextAndSubtextProps {
  text: string
  subtext: string
  orientation?: 'row' | 'column'
}

export function TextAndSubtext({
  text,
  subtext,
  orientation = 'row',
}: TextAndSubtextProps) {
  return (
    <TextAndSubtextContainer
      data-testid="text-and-subtext-component"
      flexDirection={orientation}
    >
      <Typography color="neutral.90" variant="bodyMdBold">
        {text}
      </Typography>
      <Typography color="neutral.90" variant="bodyMdRegular">
        {subtext}
      </Typography>
    </TextAndSubtextContainer>
  )
}
