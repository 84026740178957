export type Attributes = {
  [attributeName: string]: string | number | boolean | Array<string | number>
}

class FeatureFlag {
  constructor() {}

  initClientWithCompany(): void {
    Promise.resolve()
  }

  getTreatment(splitName: string, attributes?: Attributes): Promise<void> {
    return Promise.resolve()
  }

  getTreatmentByCompany(
    splitName: string,
    attributes?: Attributes,
  ): Promise<void> {
    return Promise.resolve()
  }
}

const featureFlag = new FeatureFlag()

export { featureFlag }
