import { startMonitoring, startLogs } from '../../utils'

startMonitoring()
startLogs()

import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { App as rootComponent } from './App'
import { Modules } from '../../enums/Modules'

interface ClientDeductionsCustomProps {
  language: string
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  domElementGetter: () =>
    document.querySelector(
      `[data-app="${Modules.APP_NAME}-${Modules.CLIENT_DEDUCTIONS_NAME}"]`,
    ) as Element,
})

const bootstrap = [
  reactLifecycles.bootstrap,
  async (customProps: ClientDeductionsCustomProps) => {
    /*
      TODO: We need to test and analyse performance using
       require instead System.import with all json on public file.
     */
    const imports = await Promise.all([
      require(`../../translations/${customProps.language}.json`),
      require(`./translations/${customProps.language}.json`),
    ])

    const resources = {
      [customProps.language]: {
        shared: imports[0],
        'client-deductions': imports[1],
      },
    }

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        ns: ['shared', 'client-deductions'],
        defaultNS: 'client-deductions',
        lng: customProps.language,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      })
    return Promise.resolve()
  },
]

const mount = [reactLifecycles.mount]

const unmount = [reactLifecycles.unmount]

export { bootstrap, mount, unmount }
