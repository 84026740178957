import React from 'react'

import {
  BlockDivider,
  BlockDividerTitle,
  BlockDividerDescription,
} from '@creditas/block-divider'
import { DeductionTitle } from './components'
import { Box } from './clientDeductions.style'
import { DeductionsData } from './interfaces'
import { ProductType } from '../../enums'
import { Content } from '@creditas/icons'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas-ui/react'
import { IconAlarmClockTimeLoading } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/react'

interface Props {
  data: DeductionsData
}

const ClientDeductions = ({
  data: { deductions, total, referenceMonthName },
}: Props) => {
  const { t } = useTranslation()

  const renderQuantity = (quantity: number) => {
    return quantity > 1 ? `(${quantity})` : ''
  }

  const hasDeductions = !!deductions?.length

  const renderProductType = (type: string) => {
    const key = {
      [ProductType.SalaryAdvance]: 'salaryAdvance',
      [ProductType.Discharging]: 'loan',
      [ProductType.Requesting]: 'loan',
      [ProductType.Product]: 'product',
      [ProductType.Refinancing]: 'refinancing',
    }

    return t(`productType.${key[type] || 'othersProducts'}`)
  }

  return (
    <Box>
      <DeductionTitle
        color="neutral.90"
        variant="headingSmMedium"
        component="h1"
      >
        {t('title')} {referenceMonthName}
      </DeductionTitle>
      <Typography color="neutral.80" variant="bodyLgRegular">
        {hasDeductions && t('description')}
        {!hasDeductions && t('description_empty')}
      </Typography>

      {hasDeductions && (
        <div className="deductions-container">
          <div className="list-deductions">
            {deductions?.map((item, i) => {
              return (
                <div key={i} className="item-list">
                  <Typography color="neutral.80" variant="bodyMdMedium">
                    {renderProductType(item.productType)}
                    {renderQuantity(item.quantity)}
                  </Typography>
                  <div
                    className={`box ${item.isPending ? 'isPending' : ''}`}
                    title={`${item.isPending ? 'Em análise' : ''}`}
                  >
                    {item.isPending && (
                      <GlobalIcons
                        Icon={IconAlarmClockTimeLoading}
                        size="medium"
                        marginRight="2px"
                      />
                    )}
                    <Typography color="neutral.80" variant="bodyMdLight">
                      {item.amount}
                    </Typography>
                  </div>
                </div>
              )
            })}
          </div>

          {hasDeductions && (
            <div className="total">
              <Typography color="neutral.80" variant="bodyMdMedium">
                {t('totalDiscounts')}
              </Typography>
              <Typography color="neutral.80" variant="bodyMdLight">
                {total}
              </Typography>
            </div>
          )}
        </div>
      )}
    </Box>
  )
}

export { ClientDeductions }
